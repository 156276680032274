import React, { useEffect, useState } from "react";
import { Table, Form, Button, Row, Col } from "react-bootstrap";
import axios from "axios";

const DrillPipeLookup = () => {
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    const [drillPipes, setDrillPipes] = useState([]);
    const [gradeProperties, setGradeProperties] = useState([]);
    const [selectedPipeSize, setSelectedPipeSize] = useState(null);
    const [selectedWeight, setSelectedWeight] = useState(null);
    const [selectedGrade, setSelectedGrade] = useState(null);
    const [calculatedStrengths, setCalculatedStrengths] = useState(null);
    const [selectedPipe, setSelectedPipe] = useState(null);
    const [minYieldStrength, setMinYieldStrength] = useState(0);
    const [minTensileStrength, setMinTensileStrength] = useState(0);

    useEffect(() => {
        const fetchDrillPipes = async () => {
            try {
                const response = await axios.get(`${baseURL}/input/dp_library`, {
                    withCredentials: true,
                });
                setDrillPipes(response.data);
            } catch (error) {
                console.error("Error fetching drill pipe data:", error);
            }
        };

        const fetchGradeProperties = async () => {
            try {
                const response = await axios.get(`${baseURL}/input/dp_grade_properties`, {
                    withCredentials: true,
                });
                setGradeProperties(response.data);
            } catch (error) {
                console.error("Error fetching grade properties:", error);
            }
        };

        fetchDrillPipes();
        fetchGradeProperties();
    }, [baseURL]);

    const calculateStrengths = (pipe, grade) => {
        console.log('pipe.dp_size', pipe.dp_size);
        const pipeSize = pipe.dp_size;
        const pipeIntDiam = pipe.dp_id;
        const wallThickness = (parseFloat(pipeSize) - parseFloat(pipeIntDiam)) / 2;

        // Find grade-specific properties
        const gradeProps = gradeProperties.find(g => g.grade === grade);
        if (!gradeProps) {
            console.error("Grade properties not found!");
            return;
        }

        const yieldStrength = gradeProps.min_yield_strength;
        const tensileStrength = gradeProps.min_tensile_strength;
        setMinYieldStrength(yieldStrength);
        setMinTensileStrength(tensileStrength);

        // Calculate strengths
        const burstStrength = (2 * yieldStrength * wallThickness) / pipe.dp_size;
        const collapseStrength = (0.875 * yieldStrength * wallThickness) / pipe.dp_size;
        const crossSectionalArea = Math.PI * ((pipe.dp_size / 2) ** 2 - (pipe.dp_id / 2) ** 2);
        const tensileStrengthValue = tensileStrength * crossSectionalArea;

        setCalculatedStrengths({ burstStrength, collapseStrength, tensileStrength: tensileStrengthValue });
    };

    // Filter pipes based on selected size, weight, and grade
    const filterDrillPipes = () => {
        return drillPipes.filter(pipe =>
            (!selectedPipeSize || parseFloat(pipe.dp_size) === selectedPipeSize) &&
            (!selectedWeight || pipe.dp_nom_wt === selectedWeight) &&
            (!selectedGrade || pipe.dp_grade === selectedGrade)
        );
    };

    // Get available weights for the selected pipe size
    const availableWeights = () => {
        if (selectedPipeSize) {
            const filteredPipes = drillPipes.filter(pipe => pipe.dp_size === selectedPipeSize);
            return Array.from(new Set(filteredPipes.map(pipe => pipe.dp_nom_wt)));
        }
        return [];
    };

    const handleSizeChange = (e) => {
        setSelectedPipeSize(parseFloat(e.target.value));
        setSelectedWeight('');
        setSelectedGrade('');
    };

    const handleWeightChange = (e) => {
        setSelectedWeight(parseFloat(e.target.value));
        setGradeProperties('');

        const fetchGradeProperties = async () => {
            try {
                const response = await axios.get(`${baseURL}/input/dp_grade_properties`, {
                    withCredentials: true,
                });
                setGradeProperties(response.data);
            } catch (error) {
                console.error("Error fetching grade properties:", error);
            }
        };
        
        fetchGradeProperties();

    }

    return (
        <div className="container mt-3 min-vh-100">
            <h3>Drill Pipe Lookup</h3>
            <Form>
                <Row className="mb-3">
                    <Col md={4}>
                        <Form.Group controlId="pipeSizeSelect">
                            <Form.Label>Drill Pipe Size</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedPipeSize}
                                onChange={handleSizeChange}
                            >
                                <option value="">-- Select Size --</option>
                                {Array.from(new Set(drillPipes.map(pipe => pipe.dp_size))).map((size, index) => (
                                    <option key={index} value={size}>
                                        {size}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group controlId="weightSelect">
                            <Form.Label>Drill Pipe Weight</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedWeight}
                                onChange={handleWeightChange}
                            >
                                <option value="">-- Select Weight --</option>
                                {availableWeights().map((weight, index) => (
                                    <option key={index} value={weight}>
                                        {weight}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group controlId="gradeSelect">
                            <Form.Label>Drill Pipe Grade</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedGrade}
                                onChange={(e) => setSelectedGrade(e.target.value)}
                                disabled={!selectedWeight}
                            >
                                <option value="">-- Select Grade --</option>
                                {Array.from(new Set(drillPipes.map(pipe => pipe.dp_grade))).map((grade, index) => (
                                    <option key={index} value={grade}>
                                        {grade}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={6} className="d-flex align-items-end">
                        <Button
                            variant="primary"
                            onClick={() => {
                                const selected = filterDrillPipes()[0];
                                if (!selected) {
                                    console.error("No matching drill pipe found!");
                                    return;
                                }
                                setSelectedPipe(selected);
                                calculateStrengths(selected, selectedGrade);
                            }}
                            disabled={!selectedPipeSize || !selectedWeight || !selectedGrade}
                        >
                            Select Drill Pipe and Calculate Strengths
                        </Button>

                    </Col>
                </Row>
            </Form>

            {selectedPipe && (
                <div className="mt-4">
                    <h5>Drill Pipe Details</h5>
                    <Table bordered>
                        <thead>
                            <tr>
                                <th>Property</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Outer Diameter</td>
                                <td>{selectedPipe.dp_size}"</td>
                            </tr>
                            <tr>
                                <td>Nominal Weight</td>
                                <td>{selectedPipe.dp_nom_wt}"</td>
                            </tr>
                            <tr>
                                <td>Approximate Weight</td>
                                <td>{selectedPipe.approx_wt}"</td>
                            </tr>
                            <tr>
                                <td>Inner Diameter</td>
                                <td>{selectedPipe.dp_id}"</td>
                            </tr>
                            <tr>
                                <td colSpan={2} className="bg-light fw-bold">Upset</td>
                            </tr>
                            <tr>
                                <td>Upset Type</td>
                                <td>{selectedPipe.upset_type}</td>
                            </tr>
                            <tr>
                                <td>Grade</td>
                                <td>{selectedPipe.dp_grade}</td>
                            </tr>
                            <tr>
                                <td>OD</td>
                                <td>{selectedPipe.dp_tj_boxdia}</td>
                            </tr>
                            <tr>
                                <td colSpan={2} className="bg-light fw-bold">Tool Joint Data</td>
                            </tr>
                            <tr>
                                <td>Connection Type</td>
                                <td>{selectedPipe.dp_conn}</td>
                            </tr>
                            <tr>
                                <td>Tool Joint OD</td>
                                <td>{selectedPipe.dp_tj_od}"</td>
                            </tr>
                            <tr>
                                <td>Tool Joint ID</td>
                                <td>{selectedPipe.dp_tj_id}"</td>
                            </tr>
                            <tr>
                                <td>Tool Joint Drift ID</td>
                                <td>"</td>
                            </tr>
                            <tr>
                                <td>Tool Joint Tensile Yield Strength</td>
                                <td>lbs</td>
                            </tr>
                            <tr>
                                <td>Tool Joint Torsion Yield Strength</td>
                                <td>Ft-lbs</td>
                            </tr>
                            <tr>
                                <td>Recommended Make-Up Torque</td>
                                <td>Ft-lbs</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            )}

            {calculatedStrengths && (
                <div className="mt-4">
                    <h5>Calculated Strengths</h5>
                    <Table bordered>
                        <thead>
                            <tr>
                                <th>Strength Type</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Burst Strength</td>
                                <td>{calculatedStrengths.burstStrength.toFixed(2)} psi</td>
                            </tr>
                            <tr>
                                <td>Collapse Strength</td>
                                <td>{calculatedStrengths.collapseStrength.toFixed(2)} psi</td>
                            </tr>
                            <tr>
                                <td>Minimum Yield Strength</td>
                                <td>{minYieldStrength.toFixed(2)} psi</td>
                            </tr>
                            <tr>
                                <td>Minimum Tensile Strength</td>
                                <td>{minTensileStrength.toFixed(2)} psi</td>
                            </tr>
                            <tr>
                                <td>Tensile Yield Strength</td>
                                <td>{calculatedStrengths.tensileStrength.toFixed(2)} lbs</td>
                            </tr>
                            <tr>
                                <td>Torsion Yield Strength</td>
                                <td> Ft-lbs</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            )}
        </div>
    );
};

export default DrillPipeLookup;
