import React, { useState } from 'react';
import { ListGroup, Badge, Card, Button } from 'react-bootstrap';

const TorqueAndDragChecklist = ({ parameters, selectedHoleItems, bhaList, frictionFactors, selectedFactors, surveyData }) => {
    const [isVisible, setIsVisible] = useState(false);

    // Helper function to check if a parameter is filled
    const isFilled = (value) => value !== undefined && value !== null && value !== '';

    // Drilling Parameters Checklist
    const drillingParamsChecklist = [
        { label: 'Mud Weight', value: parameters.mudWeight },
        { label: 'Block Weight', value: parameters.blockWeight },
        { label: 'Bit Depth', value: parameters.bitDepth },
        { label: 'Bit Torque Drilling', value: parameters.bitTorqueDrilling },
        { label: 'RPM Reaming', value: parameters.rpmReaming },
        { label: 'RPM Drilling', value: parameters.rpmDrilling },
        { label: 'ROP Drilling', value: parameters.ropDrilling },
        { label: 'WOB Sliding', value: parameters.wobSliding },
        { label: 'Pull Reaming', value: parameters.pullReaming },
        { label: 'Pumps On', value: parameters.pumpsOn },
        { label: 'Standpipe Pressure', value: parameters.pumpsOn ? parameters.standpipePressure : 'N/A' },
        { label: 'Annular Pressure', value: parameters.pumpsOn ? parameters.annularPressure : 'N/A' }
    ];

    // Hole Section Data Checklist
    const holeSectionChecklist = [
        { label: 'Hole Size', value: selectedHoleItems?.openHoleParameters?.hole_size },
        { label: 'Casing ID', value: selectedHoleItems?.casedHoleParameters?.casing_id },
        { label: 'Cased Hole Depth MD', value: selectedHoleItems?.casedHoleParameters?.depth_md },
        { label: 'Previous Casing ID', value: selectedHoleItems?.drillingLiner ? selectedHoleItems?.casingAboveDrillingLiner?.casing_id : 'N/A' },
        { label: 'Previous Casing MD', value: selectedHoleItems?.drillingLiner ? selectedHoleItems?.casingAboveDrillingLiner?.depth_md : 'N/A' }
    ];

    // BHA Data Checklist
    const bhaChecklist = [
        { label: 'Pipe Lengths', value: bhaList[1]?.length },
        { label: 'Pipe ODs', value: bhaList[2]?.length },
        { label: 'Pipe IDs', value: bhaList[3]?.length },
        { label: 'Pipe Weights', value: bhaList[6]?.length },
        { label: 'Tool Joint ODs', value: bhaList[9]?.length }
    ];

    // Friction Factors Checklist
    const frictionChecklist = [
        { label: 'Cased Hole - Tripping Out', value: frictionFactors?.casedHole?.trippingOut },
        { label: 'Cased Hole - Tripping In', value: frictionFactors?.casedHole?.trippingIn },
        { label: 'Open Hole - Tripping Out', value: frictionFactors?.openHole?.trippingOut },
        { label: 'Open Hole - Tripping In', value: frictionFactors?.openHole?.trippingIn }
    ];

    // Activity Selection Checklist
    const activityChecklist = [
        { label: 'Tripping Out', value: selectedFactors?.trippingOut },
        { label: 'Tripping In', value: selectedFactors?.trippingIn },
        { label: 'Rotating Off Bottom', value: selectedFactors?.rotatingOffBottom },
        { label: 'Rotating On Bottom', value: selectedFactors?.rotatingOnBottom },
        { label: 'Slide Drilling', value: selectedFactors?.slideDrilling },
        { label: 'Back Reaming', value: selectedFactors?.backReaming }
    ];

    // Survey Data Checklist
    const surveyChecklist = [
        { label: 'Survey Data', value: surveyData?.length }
    ];

    // Render checklist group
    const renderChecklist = (title, items) => (
        <Card className="mb-3">
            <Card.Header>{title}</Card.Header>
            <ListGroup variant="flush">
                {items.map((item, idx) => (
                    <ListGroup.Item key={idx} className="d-flex justify-content-between align-items-center">
                        {item.label}
                        <Badge bg={isFilled(item.value) ? 'success' : 'danger'}>
                            {isFilled(item.value) ? 'Complete' : 'Incomplete'}
                        </Badge>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </Card>
    );

    return (
        <>
            <Button onClick={() => setIsVisible(!isVisible)} variant="secondary" className="me-2">
                {isVisible ? 'Hide Checklist' : 'Show Checklist'}
            </Button>
            <div style={{ position: 'fixed', right: '20px', top: '50px', zIndex: 1000 }}>

                {isVisible && (
                    <div style={{ width: '300px', height: '80vh', overflowY: 'auto', backgroundColor: '#fff', border: '1px solid #ddd', borderRadius: '5px', padding: '10px' }}>
                        {renderChecklist('Drilling Parameters', drillingParamsChecklist)}
                        {renderChecklist('Hole Section Data', holeSectionChecklist)}
                        {renderChecklist('BHA Data', bhaChecklist)}
                        {renderChecklist('Friction Factors', frictionChecklist)}
                        {renderChecklist('Activity Selection', activityChecklist)}
                        {renderChecklist('Survey Data', surveyChecklist)}
                    </div>
                )}
            </div>
        </>
        
    );
};

export default TorqueAndDragChecklist;
