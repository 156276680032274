import React, { useState, useContext } from "react";
import { Tabs, Tab, Form, Table, Button, Row, Col } from "react-bootstrap";
import DepthParameterInput from "./Monitoring/DepthParameterInput";
import { GlobalStateContext } from "../../GlobalStateContext";

const DrillingAndFrictionFactors = ({
    parameters,
    frictionFactors,
    selectedFactors,
    handleParameterChange,
    handleFrictionChange,
    handleCheckboxChange,
}) => {
    const [chartData, setChartData] = useState([]);
    const { setSelectedItems } = useContext(GlobalStateContext);

    const handleDataUpdate = (updatedData) => {
        setChartData(updatedData);
        setSelectedItems(prevState => ({
            ...prevState,
            chartDataTnDActual: updatedData
        }));
    };

    console.log('chartData', chartData);

    const handleSubmit = () => {
        const selectedActivities = Object.keys(selectedFactors).filter(
            (key) => selectedFactors[key]
        );
        console.log("Selected Parameters:", parameters);
        console.log("Selected Friction Factors:", selectedActivities);
        console.log("selectedFactors:", selectedFactors);

        alert("Form submitted successfully!");
    };

    const formatActivityName = (name) =>
        name.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());

    return (
        <>
            <Tabs defaultActiveKey="drillingParameters" className="mb-3">
                {/* General Data */}
                <Tab eventKey="generalData" title="General Data">
                    <Form className="bg-light">
                        <Row>
                            <Col md={6} lg={4} className="mb-3">
                                <Form.Group>
                                    <Form.Label>MUD WEIGHT</Form.Label>
                                    <Form.Control type="number" name="mudWeight" value={parameters.mudWeight} onChange={handleParameterChange} placeholder="Enter MUD WEIGHT" size="sm" required />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mb-3">
                                <Form.Group>
                                    <Form.Label>BIT DEPTH</Form.Label>
                                    <Form.Control type="number" name="bitDepth" value={parameters.bitDepth} onChange={handleParameterChange} placeholder="Enter BIT DEPTH" size="sm" required />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mb-3">
                                <Form.Group>
                                    <Form.Label>BLOCK WEIGHT</Form.Label>
                                    <Form.Control type="number" name="blockWeight" value={parameters.blockWeight} onChange={handleParameterChange} placeholder="Enter BLOCK WEIGHT" size="sm" required />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mb-3">
                                <Form.Group>
                                    <Form.Check type="checkbox" label="PUMPS ON" name="pumpsOn" checked={parameters.pumpsOn} onChange={handleParameterChange} />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mb-3">
                                <Form.Group>
                                    <Form.Label>STANDPIPE PRESSURE</Form.Label>
                                    <Form.Control type="number" name="standpipePressure" value={parameters.standpipePressure} onChange={handleParameterChange} placeholder="Enter STANDPIPE PRESSURE" size="sm" required />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mb-3">
                                <Form.Group>
                                    <Form.Label>ANNULAR PRESSURE</Form.Label>
                                    <Form.Control type="number" name="annularPressure" value={parameters.annularPressure} onChange={handleParameterChange} placeholder="Enter ANNULAR PRESSURE" size="sm" required />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Tab>

                {/* Sliding Drilling */}
                <Tab eventKey="slidingDrilling" title="Sliding Drilling">
                    <Form className="bg-light">
                        <Row>
                            <Col md={6} lg={4} className="mb-3">
                                <Form.Group>
                                    <Form.Check type="checkbox" label="SLIDING DRILLING" name="slidingDrilling" checked={selectedFactors.slidingDrilling} onChange={handleCheckboxChange} />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mb-3">
                                <Form.Group>
                                    <Form.Label>WOB</Form.Label>
                                    <Form.Control type="number" name="wobSliding" value={parameters.wobSliding} onChange={handleParameterChange} placeholder="Enter WOB" size="sm" required />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mb-3">
                                <Form.Group>
                                    <Form.Label>BIT TORQUE</Form.Label>
                                    <Form.Control type="number" name="bitTorqueSliding" value={parameters.bitTorqueSliding} onChange={handleParameterChange} placeholder="Enter BIT TORQUE" size="sm" required />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Tab>

                {/* Drilling */}
                <Tab eventKey="drilling" title="Drilling">
                    <Form className="bg-light">
                        <Row>
                            <Col md={6} lg={4} className="mb-3">
                                <Form.Group>
                                    <Form.Check type="checkbox" label="DRILLING" name="drilling" checked={selectedFactors.drilling} onChange={handleCheckboxChange} />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mb-3">
                                <Form.Group>
                                    <Form.Label>WOB</Form.Label>
                                    <Form.Control type="number" name="wobDrilling" value={parameters.wobDrilling} onChange={handleParameterChange} placeholder="Enter WOB" size="sm" required />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mb-3">
                                <Form.Group>
                                    <Form.Label>BIT TORQUE</Form.Label>
                                    <Form.Control type="number" name="bitTorqueDrilling" value={parameters.bitTorqueDrilling} onChange={handleParameterChange} placeholder="Enter BIT TORQUE" size="sm" required />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mb-3">
                                <Form.Group>
                                    <Form.Label>ROP</Form.Label>
                                    <Form.Control type="number" name="ropDrilling" value={parameters.ropDrilling} onChange={handleParameterChange} placeholder="Enter ROP" size="sm" required />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mb-3">
                                <Form.Group>
                                    <Form.Label>RPM</Form.Label>
                                    <Form.Control type="number" name="rpmDrilling" value={parameters.rpmDrilling} onChange={handleParameterChange} placeholder="Enter RPM" size="sm" required />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Tab>

                {/* Reaming */}
                <Tab eventKey="reaming" title="Reaming">
                    <Form className="bg-light">
                        <Row>
                            <Col md={6} lg={4} className="mb-3">
                                <Form.Group>
                                    <Form.Check type="checkbox" label="REAMING" name="reaming" checked={selectedFactors.reaming} onChange={handleCheckboxChange} />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mb-3">
                                <Form.Group>
                                    <Form.Label>PULL</Form.Label>
                                    <Form.Control type="number" name="pullReaming" value={parameters.pullReaming} onChange={handleParameterChange} placeholder="Enter PULL" size="sm" required />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mb-3">
                                <Form.Group>
                                    <Form.Label>BIT TORQUE</Form.Label>
                                    <Form.Control type="number" name="bitTorqueReaming" value={parameters.bitTorqueReaming} onChange={handleParameterChange} placeholder="Enter BIT TORQUE" size="sm" required />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mb-3">
                                <Form.Group>
                                    <Form.Label>SPEED</Form.Label>
                                    <Form.Control type="number" name="speedReaming" value={parameters.speedReaming} onChange={handleParameterChange} placeholder="Enter SPEED" size="sm" required />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mb-3">
                                <Form.Group>
                                    <Form.Label>RPM</Form.Label>
                                    <Form.Control type="number" name="rpmReaming" value={parameters.rpmReaming} onChange={handleParameterChange} placeholder="Enter RPM" size="sm" required />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Tab>

                {/* Tripping */}
                <Tab eventKey="tripping" title="Tripping">
                    <Form className="bg-light">
                        <Row>
                            <Col md={6} lg={4} className="mb-3">
                                <Form.Group>
                                    <Form.Check type="checkbox" label="TRIPPING" name="tripping" checked={selectedFactors.tripping} onChange={handleCheckboxChange} />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mb-3">
                                <Form.Group>
                                    <Form.Label>RPM</Form.Label>
                                    <Form.Control type="number" name="rpmTripping" value={parameters.rpmTripping} onChange={handleParameterChange} placeholder="Enter RPM" size="sm" required />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mb-3">
                                <Form.Group>
                                    <Form.Label>AXIAL SPEED</Form.Label>
                                    <Form.Control type="number" name="axialSpeedTripping" value={parameters.axialSpeedTripping} onChange={handleParameterChange} placeholder="Enter AXIAL SPEED" size="sm" required />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Tab>
                {/* Tab 2: Friction Factors */}
                <Tab eventKey="frictionFactors" title="Friction Factors">
                    <h5>Friction Factors</h5>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Activity</th>
                                <th>Cased Hole</th>
                                <th>Open Hole</th>
                                <th>Range (+/-FF)</th>
                                <th>Include in Design</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(frictionFactors.casedHole).map((activity) => (
                                <tr key={activity}>
                                    <td>{formatActivityName(activity)}</td>
                                    <td>
                                        <Form.Control
                                            type="number"
                                            value={frictionFactors.casedHole[activity]}
                                            onChange={(e) => handleFrictionChange(e, "casedHole", activity)}
                                            size="sm"
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type="number"
                                            value={frictionFactors.openHole[activity]}
                                            onChange={(e) => handleFrictionChange(e, "openHole", activity)}
                                            size="sm"
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type="number"
                                            value={frictionFactors.range[activity]}
                                            onChange={(e) => handleFrictionChange(e, "range", activity)}
                                            size="sm"
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            type="checkbox"
                                            checked={selectedFactors[activity]}
                                            onChange={(e) => handleCheckboxChange(e, activity)}


                                            disabled={activity === 'trippingIn' || activity === 'trippingOut' || activity === 'rotatingOffBottom'}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Button variant="primary" className="mt-3" onClick={handleSubmit} style={{ width: "145px" }}>
                        Submit
                    </Button>
                </Tab>

                {/* Monitoring */}
                <Tab eventKey="monitoring" title="Monitoring">
                    <div>
                        <p>Torque and Drag Monitoring</p>
                    </div>
                    <DepthParameterInput onDataUpdate={handleDataUpdate} />
                </Tab>
            </Tabs>
        </>
    );
};

export default DrillingAndFrictionFactors;
