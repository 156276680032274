// import { useContext } from 'react';
// import { GlobalStateContext } from '../GlobalStateContext';

const DivideLengthsIntoSections = (lengths, weights, ods, ids, tjOds, mw) => {
    // const { selectedItems } = useContext(GlobalStateContext);
    // const rheologicalParameters = selectedItems?.rheologicalParameters
    // const mudWeight = rheologicalParameters.mwt_rheology;
    
    // Bouyancy
    const _B = 1 - 0.015 * mw;
    console.log('mw', mw);
    console.log('bouyancy', _B);

    const sectionLengths = [];
    const sectionWeights = [];
    const sectionOds = [];
    const sectionIds = [];
    const sectionTjOds = [];

    let currentSectionLength = 0;
    let currentSectionWeight = 0;
    let currentSectionOd = 0;
    let currentSectionId = 0;
    let currentSectionTjOd = 0;

    for (let i = 0; i < lengths.length; i++) {
        let length = parseFloat(lengths[i]);
        let weight = parseFloat(weights[i]) * _B;
        let od = parseFloat(ods[i]);
        let id = parseFloat(ids[i]);
        let tjOd = parseFloat(tjOds[i]);

        while (length > 0) {
            const remainingLengthInSection = 100 - currentSectionLength;

            if (length <= remainingLengthInSection) {
                currentSectionLength += length;
                currentSectionWeight += weight * length;
                currentSectionOd = od;
                currentSectionId = id;
                currentSectionTjOd = tjOd;
                length = 0;
            } else {
                length -= remainingLengthInSection;
                currentSectionLength = 100;
                currentSectionWeight += weight * remainingLengthInSection;
                currentSectionOd = od;
                currentSectionId = id;
                currentSectionTjOd = tjOd;
            }

            if (currentSectionLength === 100) {
                sectionLengths.push(currentSectionLength);
                sectionWeights.push(currentSectionWeight);
                sectionOds.push(currentSectionOd);
                sectionIds.push(currentSectionId);
                sectionTjOds.push(currentSectionTjOd);

                currentSectionLength = 0;
                currentSectionWeight = 0;
            }
        }
    }

    if (currentSectionLength > 0) {
        sectionLengths.push(currentSectionLength);
        sectionWeights.push(currentSectionWeight);
        sectionOds.push(currentSectionOd);
        sectionIds.push(currentSectionId);
        sectionTjOds.push(currentSectionTjOd);
    }

    // console.log('section_lengths FROM FUNCTION', sectionLengths);
    // console.log('section_weights FROM FUNCTION', sectionWeights);

    return { sectionLengths, sectionWeights, sectionOds, sectionIds, sectionTjOds };
};

export default DivideLengthsIntoSections;
