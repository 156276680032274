import React, { useState, useContext } from "react";
import DrillingAndFrictionFactors from "./DrillingAndFrictionFactors";
import TorqueAndDragCalculator from "./TorqueAndDragCalculator";
import { GlobalStateContext } from "../../GlobalStateContext";
import { useRheologyData } from "../../well_information/rheology/RheologyProvider";

const DrillingDesignCalculator = () => {
    const { selectedItems } = useContext(GlobalStateContext);
    const drillingParameters = selectedItems?.drillingParameters;

    const { holeSection } = useRheologyData();
    const holeSectionRheology = holeSection.find(item => item.section_type_id === selectedItems.hole_default_parameter_id);
    const rheologicalParameters = holeSectionRheology || {};
    const mw = rheologicalParameters.mwt_rheology;

    const [parameters, setParameters] = useState({
        // General data
        mudWeight: mw,
        blockWeight: 50000,
        bitDepth: drillingParameters.string_depth,
        standpipePressure: 0,
        annularPressure: 0,

        // Slide drilling
        wobSliding: 2000,
        bitTorqueSliding: 1000,

        // Drilling
        wobDrilling: drillingParameters.wob,
        bitTorqueDrilling: drillingParameters.torque_at_bit,
        ropDrilling: drillingParameters.rop,
        rpmDrilling: drillingParameters.rpm,

        // Backreaming
        pullReaming: 5000,
        bitTorqueReaming: 2000,
        speedReaming: 30,
        rpmReaming: 60,

        // Tripping
        rpmTripping: 0,
        axialSpeedTripping: 60,

        pumpsOn: true,

        // rpm: drillingParameters.rpm,
        // wob: drillingParameters.wob,
        // bitTorque: drillingParameters.torque_at_bit,
        // rop: drillingParameters.rop,
        // spp: 0,

        // overPull: 5000,
    });

    const [frictionFactors, setFrictionFactors] = useState({
        casedHole: {
            trippingIn: 0.20,
            trippingOut: 0.20,
            rotatingOffBottom: 0.20,
            rotatingOnBottom: 0.20,
            slideDrilling: 0.20,
            backReaming: 0.20,
        },
        openHole: {
            trippingIn: 0.20,
            trippingOut: 0.20,
            rotatingOffBottom: 0.20,
            rotatingOnBottom: 0.20,
            slideDrilling: 0.20,
            backReaming: 0.20,
        },
        range: {
            trippingIn: 0.0,
            trippingOut: 0.0,
            rotatingOffBottom: 0.0,
            rotatingOnBottom: 0.0,
            slideDrilling: 0.0,
            backReaming: 0.0,
        },
    });

    const [selectedFactors, setSelectedFactors] = useState({
        trippingIn: true,
        trippingOut: true,
        rotatingOffBottom: true,
        rotatingOnBottom: true,
        slideDrilling: true,
        backReaming: true,
    });

    const chartDataTnDActual = selectedItems?.chartDataTnDActual;

    // Handler Functions
    const handleParameterChange = (e) => {
        const { name, value } = e.target;
        setParameters((prev) => ({ ...prev, [name]: value }));
    };

    const handleFrictionChange = (e, category, activity) => {
        const { value } = e.target;
        setFrictionFactors((prev) => ({
            ...prev,
            [category]: { ...prev[category], [activity]: parseFloat(value) || 0 },
        }));
    };

    const handleCheckboxChange = (e, activity) => {
        const { checked } = e.target;
        setSelectedFactors((prev) => ({ ...prev, [activity]: checked }));
    };

    return (
        <div>
            {/* Pass state and handlers to child components */}
            <DrillingAndFrictionFactors
                parameters={parameters}
                frictionFactors={frictionFactors}
                selectedFactors={selectedFactors}
                handleParameterChange={handleParameterChange}
                handleFrictionChange={handleFrictionChange}
                handleCheckboxChange={handleCheckboxChange}
            />
            <TorqueAndDragCalculator
                parameters={parameters}
                frictionFactors={frictionFactors}
                selectedFactors={selectedFactors}
                chartDataTnDActual={chartDataTnDActual}
            />
        </div>
    );
};

export default DrillingDesignCalculator;
